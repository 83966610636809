import * as React from 'react'

const CoachingWays3 = (props: any) => (
  <svg viewBox="0 0 728 605" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M.583.982h709v519h-709z" />
    <path
      d="m418.467 202.529.597-6.634a5.626 5.626 0 0 1-.902-3.487c.095-1.21.666-2.323 1.502-3.202l.321-3.588c-.843-.943-1.168-2.2-.915-3.483.257-1.315.927-2.473 1.561-3.626l.617-6.873a1.149 1.149 0 0 1-.098-.203c-.635-1.298-.732-2.754-.543-4.168a9.107 9.107 0 0 1 1.447-4.48l.251-2.775 4.757-55.338-72.327-2.556-.493 35.224-.019 1.391c1.097-1.029 2.156-1.68 3.18-1.839.965-.153 1.884.158 2.801.943 3.042 2.602.651 14.199-6.401 31.039l-.12 8.615c1.977 6.231 3.989 17.434-.431 30.827l-.049 3.217-.185 12.899 63.634 1.386 1.815-23.289ZM440.361 212.753c.206-.447.595-.652 1.004-.632a1.093 1.093 0 0 1 .427.107c.541.25.777.889.528 1.429a1.169 1.169 0 0 1-.372.461.843.843 0 0 1-.126.077l-.054.02c-.254.109-.562.113-.879-.033a1.077 1.077 0 0 1-.528-1.429ZM342.501 135.836l-5.999-1.393 3.564 7.279 2.435-5.886ZM450.871 100.188l-12.018 8.676c-.544.392.054 1.255.597.863l12.018-8.676c.543-.393-.054-1.255-.597-.863ZM447.828 112.241l-6.478.429c-.679.048-.572 1.091.106 1.043l6.479-.429c.678-.048.572-1.091-.107-1.043Z"
      fill="#fff"
    />
    <path
      d="M454.36 199.764c-.63.285-1.25.575-1.88.86-.616.28-.254 1.26.362.98.63-.285 1.25-.575 1.88-.86.616-.28.254-1.26-.362-.98ZM393.214 240.454l-2.698-5.92c-.281-.616-1.262-.256-.981.359l2.698 5.92c.272.612 1.262.256.981-.359ZM383.543 232.555c-.549.65-1.098 1.3-1.638 1.954-.434.522.364 1.195.807.677l1.638-1.953c.434-.523-.364-1.196-.807-.678Z"
      fill="#4543B9"
    />
    <path
      d="M370.872 196.062c.404-.874 1.176-1.297 1.991-1.248.267.01.54.08.805.191l.047.022a2.132 2.132 0 0 1 1.046 2.831 2.26 2.26 0 0 1-.734.903 3.593 3.593 0 0 1-.252.155l-.104.053c-.508.218-1.11.222-1.734-.067a2.137 2.137 0 0 1-1.065-2.84ZM364.564 212.636a.5.5 0 0 1-.236-.222.514.514 0 0 1 .202-.708 187.106 187.106 0 0 0 5.511-3.235c6.694-4.056 8.497-5.143 15.401-.882 8.109 5.009 20.519 16.826 20.643 16.951.213.2.219.53.019.742a.524.524 0 0 1-.744.018c-.128-.115-12.458-11.861-20.468-16.813-6.356-3.929-7.726-3.094-14.303.88-1.569.947-3.344 2.023-5.543 3.255a.54.54 0 0 1-.482.014Z"
      fill="#fff"
    />
    <path
      d="M396.3 216.397a.595.595 0 0 1-.207-.164.523.523 0 0 1 .114-.737c.714-.517 1.36-1.009 1.948-1.449 5.083-3.851 6.612-5.008 15.275.811a.518.518 0 0 1 .139.73.518.518 0 0 1-.731.136c-8.034-5.392-9.182-4.522-14.05-.843-.592.449-1.247.937-1.966 1.464a.524.524 0 0 1-.522.052Z"
      fill="#fff"
    />
    <path
      d="M364.251 220.813a.528.528 0 0 1-.297-.533c.057-.809-.094-23.369-.149-31.997a.562.562 0 0 1 .153-.381.56.56 0 0 1 .376-.154l49.743.888a.52.52 0 0 1 .513.53l-.305 30.873a.524.524 0 0 1-.535.521.526.526 0 0 1-.522-.535l.308-30.341-48.685-.873c.045 6.298.214 31.023.141 31.599a.521.521 0 0 1-.583.453c-.064-.007-.111-.029-.158-.05ZM357.863 136.336c.018-.014.041-.015.059-.029l.542-38.419 71.233 2.525-4.706 54.786a.529.529 0 0 0 .473.569.526.526 0 0 0 .569-.472l4.756-55.338a.556.556 0 0 0-.132-.4.48.48 0 0 0-.373-.172l-72.323-2.565a.52.52 0 0 0-.544.516l-.554 39.621c.319-.225.652-.432 1-.622Z"
      fill="#4543B9"
    />
    <path
      d="m420.388 221.085 1.811-23.279a.53.53 0 0 0-.487-.564.519.519 0 0 0-.563.485l-1.776 22.788-62.613-1.367.827-58.812-1.083 2.345-.794 56.976a.517.517 0 0 0 .309.481.454.454 0 0 0 .204.049l63.634 1.387a.544.544 0 0 0 .531-.489ZM358.464 97.888l-.541 38.442c-1.798-.028-3.619 1.343-5.523 3.58l.466-40.633a7.207 7.207 0 0 1 2.082-5l2.007-2.042a7.272 7.272 0 0 1 5.831-2.139l62.087 5.594a7.23 7.23 0 0 1 5.847 3.977.535.535 0 0 1-.024.519.528.528 0 0 1-.461.238l-71.771-2.536Z"
      fill="#4543B9"
    />
    <path
      d="m420.343 221.256-1.629 3.527a7.283 7.283 0 0 1-6.642 4.215l-53.368-.338a7.257 7.257 0 0 1-5.151-2.175 7.211 7.211 0 0 1-2.087-5.186l.092-8.358.53-.071c7-16.48 3.867-30.403 1.78-36.492a9.694 9.694 0 0 1 .248-6.908 181.863 181.863 0 0 0 3.47-8.863l-.818 58.522 63.11 1.371a.546.546 0 0 1 .44.248c.083.162.098.35.025.508Z"
      fill="#4543B9"
    />
    <path
      d="M377.446 94.935a.523.523 0 0 1 .235-.998l15.521.45a.525.525 0 0 1-.031 1.047l-15.521-.45a.524.524 0 0 1-.204-.049Z"
      fill="#fff"
    />
    <path
      d="M425.522 103.498a2.933 2.933 0 0 0-.986-.241c-1.073-.055-2.028.543-2.496 1.557a2.659 2.659 0 0 0 1.303 3.526c.727.336 1.495.363 2.189.063l.117-.059c.118-.059.217-.126.316-.193.386-.273.7-.659.911-1.115.606-1.312.026-2.9-1.354-3.538ZM421.621 172.96l-.584 3.298 1.871-1.992-1.287-1.306ZM424.924 144.024c.051-3.86.093-7.724.14-11.575.006-.675-1.038-.57-1.044.105-.051 3.86-.093 7.725-.14 11.575-.002.666 1.042.561 1.044-.105ZM426.027 149.061a.522.522 0 0 1-.098-.892l1.719-1.295c7.524-5.659 17.835-13.407 20.217-18.663.901-2 .977-4.663-.639-5.918-1.4-1.086-6.08-2.469-19.206 8.044a.527.527 0 1 1-.66-.825c13.22-10.593 18.517-9.591 20.501-8.055 2.134 1.652 1.989 4.882.95 7.18-2.493 5.498-12.499 13.026-20.539 19.067l-1.719 1.295a.492.492 0 0 1-.526.062Z"
      fill="#4543B9"
    />
    <path
      d="M438.918 154.055c.025-4.245-1.903-7.789-5.735-10.541-.33.198-.673.378-1.012.549.029.036.062.062.095.089 3.744 2.576 5.624 5.905 5.59 9.898-.057 7.843-7.312 16.031-11.001 18.482-1.75 1.167-3.38 1.217-4.595.147-1.711-1.513-2.387-5.245-.569-8.911 3.017-6.069 5.258-9.133 5.285-9.166a.511.511 0 0 0-.116-.73.512.512 0 0 0-.731.114c-.093.126-2.307 3.157-5.377 9.315-1.865 3.745-1.514 8.107.817 10.165.312.28.65.504 1.013.672 1.418.655 3.138.41 4.852-.729 3.865-2.562 11.43-11.126 11.484-19.354Z"
      fill="#4543B9"
    />
    <path
      d="M420.657 187.226c-.112-.052-.219-.113-.327-.174-.609-.371-2.025-1.251-2.328-3.118-.4-2.466 1.234-5.809 4.976-10.212a.537.537 0 0 1 .742-.063c.218.19.247.52.065.741-3.489 4.101-5.08 7.251-4.745 9.37.195 1.219 1.001 1.873 1.84 2.385 1.828 1.115 5.519-.827 8.992-4.744 4.613-5.186 7.676-12.431 4.645-16.258a.54.54 0 0 1 .082-.741.54.54 0 0 1 .742.083c3.557 4.476.066 12.257-4.68 17.608-2.882 3.252-7.159 6.437-10.004 5.123Z"
      fill="#4543B9"
    />
    <path
      d="M433.523 178.039a.523.523 0 0 0-1.028.191c1.164 5.934-.775 10.255-2.613 12.838-2.682 3.775-6.519 5.909-8.276 6.159-1.175.169-2.43-.716-3.003-2.098-.379-.943-1.249-4.303 3.781-7.941a.53.53 0 0 0 .21-.479 18.133 18.133 0 0 1-1.099-.169c-5.335 4.006-4.307 7.879-3.863 8.976.469 1.142 1.287 2.017 2.243 2.447a3.36 3.36 0 0 0 1.879.292c1.989-.289 6.09-2.528 8.974-6.593 1.953-2.733 4.03-7.329 2.795-13.623ZM427.427 170.753c-2.224-.305-3.105-2.563-2.649-4.554.407-1.765 1.659-3.129 2.93-4.337.504-.479-.216-1.297-.72-.818-1.877 1.786-3.67 4.052-3.403 6.806.214 2.085 1.699 3.697 3.785 3.983.697.107.749-.986.057-1.08Z"
      fill="#4543B9"
    />
    <path
      d="M426.616 185.473a.434.434 0 0 0-.238-.245c-2.805-1.375-3.3-4.934-.888-6.947.537-.452-.183-1.27-.72-.818-2.905 2.418-2.429 6.95.97 8.656.306-.221.588-.44.876-.646ZM425.208 194.916a2.85 2.85 0 0 1-.371-5.421c.649-.276.158-1.248-.481-.967-3.454 1.476-3.103 6.425.522 7.421.663.182 1.002-.846.33-1.033ZM352.568 213.08a.526.526 0 0 1-.684.283.527.527 0 0 1-.284-.685 55.304 55.304 0 0 0 1.639-4.357c4.423-13.402 2.407-24.596.43-30.827-.098-.327-.204-.636-.305-.931a10.204 10.204 0 0 1 .259-7.276c.06-.13.115-.274.166-.408 7.052-16.84 9.453-28.433 6.401-31.039-.916-.785-1.827-1.092-2.801-.943-1.024.159-2.083.81-3.18 1.839-2.601 2.446-5.414 6.995-8.534 12.035-1.524 2.468-3.101 5.025-4.781 7.535l-.206.3c-8.117 12.137-15.787 23.6-23.456 59.099a.52.52 0 0 1-.733.362.534.534 0 0 1-.294-.588c7.709-35.684 15.431-47.236 23.609-59.457l.198-.304c1.675-2.501 3.247-5.049 4.768-7.508 3.407-5.516 6.475-10.467 9.449-12.865 1.008-.81 2.007-1.331 2.998-1.494 1.267-.194 2.497.204 3.64 1.184 4.767 4.087-1.891 22.204-6.285 32.65a9.195 9.195 0 0 0-.232 6.531c2.23 6.54 4.965 19.658-1.16 35.322a42.522 42.522 0 0 1-.622 1.542Z"
      fill="#4543B9"
    />
    <path
      d="M356.938 137.58c.057-.662-.978-.847-1.039-.175-.155 1.904-.288 3.931-1.249 5.621-1.218 2.147-3.966 3.441-5.931 1.473-.169.317-.333.648-.502.965 1.613 1.456 3.999 1.361 5.712.029 2.408-1.857 2.773-5.098 3.009-7.913Z"
      fill="#4543B9"
    />
    <path
      d="M407.557 159.578c-.14-.064-.191-.223-.136-.367.235-.558.313-1.143.427-1.734a11.851 11.851 0 0 0-.916-1.789c-.373-1.448-.763-2.881-1.136-4.329-.37-1.379-2.12-5.676-3.133-5.907.322-4.446 1.103-5.305 1.411-9.746.441-6.345 1.467-12.464-.147-18.697-.576-2.231-3.006-2.777-4.866-2.033a15.077 15.077 0 0 0-12.99-2.893c-8.926 2.2-13.53 10.088-14.358 18.546-.391-.011-.56.6-.969.603-3.571.112-4.803 5.109-1.408 6.383.925.348 2.748.615 3.613.777-.376 4.195-1.304 18.455-1.304 18.455s-4.541 1.821-4.779 1.971a58.145 58.145 0 0 0-5.215 3.033l-.076 3.081 43.825 2.649c1.959-2.11 3.216-4.612 3.069-7.356a2.875 2.875 0 0 0-.912-.647Z"
      fill="#fff"
    />
    <path
      d="M389.706 116.685c4.336-.03 9.186-.511 13.8-.062-1.785-2.947-5.467-5.314-12.535-6.06-12.535-1.329-18.786 3.337-19.664 11.682l-.877 8.345 1.608.291c1.3-.021 2.118.142 3.124.291.79.128 1.316.607 1.354-.188.687-13.886 3.277-14.225 13.19-14.299Z"
      fill="#4543B9"
    />
    <path
      d="M361.842 163.08c-.047-.022.143-.849.1-.88a.528.528 0 0 1-.042-.742c2.262-2.545 7.899-4.142 9.44-4.537l1.059-18.049c-2.324-.261-3.543-1.174-4.174-1.916a3.89 3.89 0 0 1-.925-2.946c.078-.709.377-1.722 1.404-2.501 1.467-1.118 3.938-1.422 7.342-.877a.523.523 0 1 1-.16 1.032c-3.071-.481-5.329-.248-6.549.679-.583.454-.927 1.051-.995 1.787-.05.451-.022 1.322.689 2.159.783.926 2.147 1.477 3.961 1.592a.528.528 0 0 1 .491.554l-1.109 18.941a.524.524 0 0 1-.402.48c-.064.016-6.922 1.659-9.277 4.308-.169.17-.648 1.011-.853.916ZM393.856 138.721a.522.522 0 0 1 .299-.991c.97.154 1.811.023 2.244-.352.246-.214.366-.498.359-.874-.009-.839-1.756-1.589-2.604-1.812a.525.525 0 0 1-.382-.594l.745-4.748a.524.524 0 0 1 1.034.162l-.672 4.296c.939.32 2.919 1.178 2.93 2.674.013.683-.245 1.264-.718 1.678-.694.594-1.785.801-3.092.593a.37.37 0 0 1-.143-.032ZM397.461 130.202l-.275 1.013c-.354 1.305 1.681 1.849 2.034.544l.276-1.013c.353-1.304-1.681-1.848-2.035-.544ZM383.93 129.632l-.275 1.013c-.354 1.305 1.681 1.849 2.034.545l.276-1.013c.344-1.309-1.691-1.853-2.035-.545ZM386.102 126.988c-.865-.038-1.715-.035-2.566.092-.667.098-.387 1.108.281 1.01.754-.115 1.536-.093 2.3-.056.663.035.661-1.015-.015-1.046ZM399.018 123.989c-.635.124-1.233.367-1.883.451-.664.089-.658 1.13.015 1.046.732-.091 1.423-.337 2.148-.487.658-.126.378-1.136-.28-1.01Z"
      fill="#4543B9"
    />
    <path
      d="M388.976 162.446c-1.996-.922-4.881-2.638-5.514-5.041a.53.53 0 0 1 .595-.652c.074.012 7.858 1.315 12.452-.719 5.354-2.382 6.139-11.627 7.45-26.964.136-1.619.285-3.312.44-5.069.678-7.669-3.73-11.895-13.471-12.927-11.599-1.224-18.202 2.659-19.091 11.224-.749 7.162-.579 8.459-.546 8.633a.523.523 0 0 1-.922.477c-.309-.458-.165-3.564.422-9.221.954-9.122 8.14-13.436 20.242-12.149 10.29 1.094 15.146 5.821 14.417 14.064-.156 1.758-.299 3.441-.436 5.06-1.34 15.718-2.145 25.203-8.066 27.832-1.45.64-3.157.98-4.857 1.132.005.014-.004.033.001.046l-.486 4.608a.538.538 0 0 1-.236.388.538.538 0 0 1-.448.063c-.098-.033-.901-.303-1.946-.785Zm-4.179-4.527c1.161 2.094 4.46 3.55 5.833 4.083l.397-3.802c-2.569.123-4.97-.117-6.23-.281Z"
      fill="#4543B9"
    />
    <path
      d="M383.968 157.265c.9 3.442 7.107 5.462 7.107 5.462l.487-4.608-7.594-.854ZM377.486 166.375c-.037-.017-.074-.034-.107-.061-3.619-2.822-5.934-8.543-6.034-8.792a.52.52 0 0 1 .291-.679.522.522 0 0 1 .68.292c.02.054 2.314 5.697 5.707 8.348a.51.51 0 0 1 .092.731.504.504 0 0 1-.629.161ZM399.15 140.071a3.597 3.597 0 0 0-1.497 1.747c-.255.627.696 1.066.952.439a2.703 2.703 0 0 1 1.143-1.323c.581-.353-.02-1.207-.598-.863Z"
      fill="#4543B9"
    />
    <path
      d="M405.639 147.583c.255-3.811-2.659-3.971-2.659-3.971s-1.014 5.922-3.052 9.011c-1.985 3.001-8.468 6.477-10.801 3.909-2.329-2.577-6.169-7.568-7.63-5.409-1.461 2.159 5.172 10.732 5.172 10.732l-1.817 3.248 7.109-.251 9.225-6.229c.005.014 4.184-7.224 4.453-11.04Z"
      fill="#fff"
    />
    <path
      d="M383.517 166.744c-.028-.013-.047-.021-.07-.044a.52.52 0 0 1-.152-.724l2.722-4.106c-1.521-2.001-6.462-8.821-4.957-11.05.316-.464.753-.725 1.267-.759 1.619-.099 3.664 2.087 6.173 4.962.362.416.701.809 1.02 1.148.495.545 1.275.792 2.32.71 2.8-.208 6.359-2.572 7.66-4.557 1.955-2.959 2.965-8.747 2.972-8.811a.525.525 0 0 1 .542-.439 3.396 3.396 0 0 1 2.209 1.122c.72.818 1.041 1.96.936 3.413-.266 3.885-4.345 10.977-4.519 11.28-.148.248-.458.354-.72.187a.522.522 0 0 1-.189-.719c.044-.07 4.131-7.181 4.378-10.827.078-1.149-.148-2.032-.663-2.632a2.337 2.337 0 0 0-1.046-.675c-.309 1.551-1.3 6.05-3.036 8.681-1.437 2.181-5.264 4.783-8.463 5.022-1.362.105-2.458-.266-3.177-1.061a97.009 97.009 0 0 1-1.025-1.162c-1.631-1.859-4.087-4.676-5.311-4.598-.129.009-.293.046-.455.298-.86 1.274 2.493 6.684 5.148 10.123a.538.538 0 0 1 .024.609l-2.933 4.415a.51.51 0 0 1-.655.194Z"
      fill="#4543B9"
    />
    <path
      d="M402.981 161.45a.521.521 0 0 1-.204-.783c3.134-4.287 3.436-6.779 3.131-8.105-.256-1.134-.967-1.531-.995-1.543a.527.527 0 0 1-.216-.71.519.519 0 0 1 .705-.227c.047.021 1.149.598 1.531 2.197.545 2.25-.571 5.28-3.3 9.01a.538.538 0 0 1-.652.161Z"
      fill="#4543B9"
    />
    <path
      d="M404.112 164.49a.534.534 0 0 1-.146-.101.522.522 0 0 1-.019-.742c.026-.033 3.285-3.462 3.168-6.248-.035-.807-.336-1.454-.946-1.995a.528.528 0 0 1-.042-.742.528.528 0 0 1 .743-.041 3.728 3.728 0 0 1 1.292 2.741c.126 3.231-3.315 6.859-3.457 7.019a.516.516 0 0 1-.593.109Z"
      fill="#4543B9"
    />
    <path
      d="M404.421 168.009a.521.521 0 0 1-.097-.891c.036-.029 3.634-2.748 3.771-5.372.041-.85-.276-1.584-.987-2.251a.522.522 0 0 1-.019-.742.524.524 0 0 1 .744-.018c.942.875 1.38 1.913 1.32 3.071-.171 3.116-4.02 6.035-4.191 6.16a.571.571 0 0 1-.541.043ZM383.309 156.927l1.153 7.77 2.207-2.842-3.36-4.928ZM405.015 112.962c-1.548-1.867-4.073-2.107-6.34-2.149-3.737-.066-7.45.5-11.108 1.192-1.663.317-4.83.412-5.435 2.481a1.98 1.98 0 0 0-.054.878c-.347-.499-1.241-.155-.938.414 1.244 2.403 3.407 3.256 5.956 3.789 3.261.682 6.683 1.212 10.013 1.18 2.526-.03 5.702-.268 7.56-2.232 1.498-1.578 1.785-3.816.346-5.553Z"
      fill="#4543B9"
    />
    <path
      d="M361.356 165.407a.526.526 0 0 1-.305-.491l1.567-59.092a.527.527 0 0 1 .53-.511l55.479.815a.524.524 0 0 1 .377.163c.1.103.15.239.141.382l-2.379 61.584a.561.561 0 0 1-.172.372.527.527 0 0 1-.381.14l-54.667-3.308a.63.63 0 0 1-.19-.054Zm2.299-59.036-1.545 58.063 53.622 3.244 2.345-60.505-54.422-.802Z"
      fill="#4543B9"
    />
    <path
      d="m364.236 171.716.128 3.593 24.325.033-.405-3.213-24.048-.413ZM400.131 171.933s-3.602-2.883-4.52-.699c-.918 2.184 4.652 6.416 4.652 6.416s4.662-5.536 3.163-6.849c-1.512-1.308-3.295 1.132-3.295 1.132ZM68.846 455.796c1.513 5.648.58 11.007-7.438 13.155-5.653 1.515-11.505-2.026-13.018-7.673-1.514-5.648 1.837-11.445 7.49-12.96 5.653-1.514 11.453 1.831 12.966 7.478ZM195.745 325.487l-3.107-5.325-33.664 11.597.342 3.459 36.429-9.731ZM118.313 320.046l-48.482 12.99 48.391 146.237 50.316-13.482-50.225-145.745Z"
      fill="#fff"
    />
    <path
      d="m89.138 388.751-17.546 4.702 10.494 32.951 15.993-4.285-8.941-33.368ZM100.89 301.866l4.879 14.514-23.146 7.086-5.002-15.365 23.269-6.235ZM130.31 256.856c-.971 4.156-1.956 8.315-2.927 12.471-.157.701.913.999 1.084.294.971-4.156 1.956-8.315 2.927-12.471.157-.701-.913-.999-1.084-.294ZM137.072 266.027a2.1 2.1 0 0 0 1.486-2.571 2.1 2.1 0 0 0-4.058 1.088 2.1 2.1 0 0 0 2.572 1.483ZM92.318 428.771l-2.727 4.656 4.69 2.669 3.36-4.975-5.323-2.35Z"
      fill="#fff"
    />
    <path
      d="M115.189 396.034c-1.357.364-2.588.304-3.457-.197-.723-.406-1.188-1.075-1.35-1.961-.384-1.994 2.163-3.681 3.256-4.303l-2.136-5.735a.564.564 0 0 1 .332-.718.566.566 0 0 1 .719.331l2.302 6.186a.542.542 0 0 1-.28.689c-.885.447-3.361 2.009-3.097 3.332.104.556.374.948.8 1.194.741.415 1.956.36 3.266-.126a.574.574 0 0 1 .718.332.564.564 0 0 1-.332.718 3.66 3.66 0 0 1-.741.258ZM106.372 387.025l.413 1.373c.535 1.774-2.223 2.618-2.762.83l-.413-1.373c-.536-1.774 2.223-2.618 2.762-.83ZM125.093 382.009l.413 1.373c.535 1.774-2.223 2.618-2.762.829l-.413-1.372c-.549-1.771 2.223-2.618 2.762-.83ZM119.678 376.912c1.152-.293 2.293-.509 3.476-.571.931-.055.821 1.383-.095 1.434-1.05.056-2.097.292-3.108.548-.899.226-1.172-1.185-.273-1.411ZM100.318 384.692c.529-.711 1.186-1.337 1.648-2.075.497-.777 1.8-.167 1.307.624-.548.866-1.256 1.58-1.851 2.384-.549.746-1.64-.19-1.104-.933Z"
      fill="#4543B9"
    />
    <path
      d="M127.89 357.857a.558.558 0 0 0 0-1.064c-5.047-1.539-11.41-1.108-18.949 1.257-8.092 2.543-13.243 6.32-15.323 11.208a.554.554 0 0 0 .3.728c.117.044.24.056.366.022a.525.525 0 0 0 .322-.251l.042.154c.149.724.326 1.44.503 2.157 9.061-4.016 23.349-6.271 27.486-5.942 3.705.281 5.954 1.567 7.67 4.837 1.811 3.455 2.562 9.786 5.041 11.145.035.02.039.034.067.027l-.011-.042-7.514-24.236ZM131.662 419.173a.536.536 0 0 0-.528-.068c-.094.04-10.268 3.935-17.06 2.429-1.676-.375-3.176-1.217-4.558-2.57a.564.564 0 0 0-.79.017.563.563 0 0 0 .016.79c1.539 1.49 3.199 2.424 5.082 2.848 2.152.473 4.59.449 6.95.191a.555.555 0 0 0 .18.446l4.693 4.196c.139.127.33.166.512.118.042-.012.07-.019.108-.044.212-.102 5.316-2.713 5.614-7.872a.562.562 0 0 0-.219-.481ZM129.538 404.25a10.184 10.184 0 0 0 1.54-3.319c.195-.726-.945-1.065-1.123-.328a9.179 9.179 0 0 1-1.421 3.033c-.433.625.568 1.226 1.004.614Z"
      fill="#4543B9"
    />
    <path
      d="M130.438 449.975a.54.54 0 0 1-.605-.242l-14.187-22.163a.577.577 0 0 1 .104-.732l5.218-4.425a.565.565 0 0 1 .738.012l16.083 14.688a.55.55 0 0 1 .096.694l-7.114 11.899a.516.516 0 0 1-.333.269Zm-13.6-22.605 13.44 21 6.42-10.74-15.373-14.054-4.487 3.794Z"
      fill="#4543B9"
    />
    <path
      d="M149.175 471.548a.564.564 0 0 1-.691-.399l-7.566-28.237-3.96-5.037a.577.577 0 0 1 .089-.788.577.577 0 0 1 .789.089l4.031 5.137a.605.605 0 0 1 .098.199l7.596 28.349a.553.553 0 0 1-.386.687ZM154.57 444.723a.562.562 0 0 1-.49-.094l-10.903-8.285a.543.543 0 0 1-.203-.59l3.833-14.571a.554.554 0 0 1 .69-.395.564.564 0 0 1 .394.689l-3.738 14.201 9.419 7.169c.246.189 1.465 1.436 1.276 1.681a.507.507 0 0 1-.278.195Z"
      fill="#4543B9"
    />
    <path
      d="M141.579 443.199a.519.519 0 0 1-.302-.008.547.547 0 0 1-.367-.696l2.084-6.776a.56.56 0 0 1 .697-.367c.293.087.466.4.366.696l-2.084 6.776a.56.56 0 0 1-.394.375ZM112.506 431.243a.564.564 0 0 1-.554-.166.551.551 0 0 1 .029-.787l3.744-3.431a.554.554 0 0 1 .788.029.553.553 0 0 1-.029.787l-3.745 3.43a.593.593 0 0 1-.233.138ZM193.956 441.72l-13.517-9.367 6.144-9.138a.526.526 0 0 0 .055-.524.565.565 0 0 0-.406-.341l-33.139-7.092a.554.554 0 0 0-.558.21.556.556 0 0 0-.05.598l16.61 31.656a.56.56 0 0 0 .469.294.557.557 0 0 0 .492-.236l6.861-10.214 13.995 9.749c.131.1.3.115.458.087l.014-.003a.598.598 0 0 0 .357-.291l2.389-4.685a.55.55 0 0 0-.174-.703Z"
      fill="#4543B9"
    />
    <path
      d="m129.125 486.045-28.46 7.626a.56.56 0 0 1-.684-.371L61.652 372.923a.551.551 0 0 1 .354-.694l29.984-9.832a.527.527 0 0 1 .429.035.517.517 0 0 1 .269.333l36.819 122.578a.625.625 0 0 1-.049.433.574.574 0 0 1-.333.269Zm-28.248 6.4 27.396-7.34-36.481-121.486-28.907 9.484 37.992 119.342ZM200.922 461.279l-37.919 10.16a.559.559 0 0 1-.684-.371l-45.388-146.667c-.038-.14-.037-.305.049-.432a.526.526 0 0 1 .347-.273l39.975-10.712a.573.573 0 0 1 .688.385l5.73 19.425 37.601 127.793a.62.62 0 0 1-.049.433.621.621 0 0 1-.35.259Zm-37.69 8.945 36.842-9.872-37.438-127.237-5.577-18.912-38.898 10.423 45.071 145.598Z"
      fill="#4543B9"
    />
    <path
      d="m230.204 449.837-30.181 8.087a.572.572 0 0 1-.688-.385l-36.703-124.438a.625.625 0 0 1 .049-.433.58.58 0 0 1 .347-.272l32.032-8.568a.572.572 0 0 1 .688.385l34.848 124.904a.56.56 0 0 1-.053.419.508.508 0 0 1-.339.301Zm-29.938 6.868 29.103-7.798-34.545-123.833-30.94 8.276 36.382 123.355ZM97.992 485.263l-28.754 7.704a.56.56 0 0 1-.684-.371L19.946 339.062a.631.631 0 0 1 .045-.447.5.5 0 0 1 .365-.263l50.267-11.536a.56.56 0 0 1 .664.407l10.488 39.14a.565.565 0 0 1-.4.691.556.556 0 0 1-.692-.398L70.33 328.018 21.18 339.3l48.26 152.46 28.236-7.566a.565.565 0 0 1 .691.399.53.53 0 0 1-.375.67ZM161.978 468.118l-35.134 9.414a.565.565 0 0 1-.692-.399.556.556 0 0 1 .4-.691l35.134-9.415a.567.567 0 0 1 .692.399.566.566 0 0 1-.4.692Z"
      fill="#4543B9"
    />
    <path
      d="m210.475 418.521-10.578 2.834a.572.572 0 0 1-.688-.385l-21.994-73.798a.623.623 0 0 1 .05-.432.577.577 0 0 1 .346-.273l12.635-3.386a.566.566 0 0 1 .692.399l19.923 74.353a.537.537 0 0 1-.386.688Zm-10.335 1.616 9.515-2.55-19.634-73.277-11.544 3.094 21.663 72.733ZM130.136 364.222a.563.563 0 0 1-.307-1.086l38.83-11.574c.259-.099.606.078.702.382a.564.564 0 0 1-.382.701l-38.829 11.573-.014.004ZM121.595 335.317a.572.572 0 0 1-.688-.385.565.565 0 0 1 .382-.701l38.83-11.574c.287-.047.606.078.702.382a.564.564 0 0 1-.382.701l-38.83 11.573-.014.004ZM38.53 376.347a.555.555 0 0 1-.667-.36l-8.64-25.028a.546.546 0 0 1 .04-.461.53.53 0 0 1 .362-.276l37.632-8.151a.556.556 0 0 1 .66.393l6.147 22.939a.571.571 0 0 1-.386.687L38.53 376.347c.014-.003.014-.003 0 0Zm-8.046-25.157 8.257 23.932 34.093-9.944-5.866-21.891-36.484 7.903ZM32.748 336.186a.565.565 0 0 1-.555-.166l-14.126-15.557a.541.541 0 0 1-.144-.426.551.551 0 0 1 .211-.387L99.1 256.514a.56.56 0 0 1 .746.04l16.276 16.36a.523.523 0 0 1 .162.436.56.56 0 0 1-.222.404l-83.116 62.334a.6.6 0 0 1-.198.098Zm-13.442-16.025 13.378 14.723 82.193-61.637-15.476-15.555-80.095 62.469Z"
      fill="#4543B9"
    />
    <path
      d="M182.622 307.354 73.246 336.661a.565.565 0 0 1-.692-.399l-6.88-25.679a.573.573 0 0 1 .385-.688l108.938-30.942a.666.666 0 0 1 .433.048.56.56 0 0 1 .255.337l7.319 27.314a.548.548 0 0 1-.382.702ZM73.489 335.442l108.299-29.019-7.03-26.238-107.865 30.641 6.596 24.616Z"
      fill="#4543B9"
    />
    <path
      d="M79.941 328.065a.556.556 0 0 1-.67-.375l-5.016-15.361a.516.516 0 0 1 .045-.447.546.546 0 0 1 .347-.272l23.269-6.235a.555.555 0 0 1 .666.36l4.879 14.515a.517.517 0 0 1-.035.428.586.586 0 0 1-.329.283l-23.146 7.086c.004.014-.01.018-.01.018Zm-4.442-15.515 4.65 14.276 22.079-6.756-4.524-13.47-22.205 5.95ZM57.883 317.27a.558.558 0 0 1-.534-.142l-15.34-15.276c-.222-.211-.214-.572-.003-.794.21-.221.573-.213.794-.003l15.34 15.277c.222.21.214.572.004.793a.753.753 0 0 1-.261.145ZM43.624 327.189l-15.055-14.994a.565.565 0 0 0-.794.003.563.563 0 0 0 .002.793l15.12 15.067c.198-.323.45-.615.727-.869ZM48.299 366.688a.5.5 0 0 1-.387-.046l-8.736-4.282a.55.55 0 0 1-.305-.412.546.546 0 0 1 .167-.494l6.058-5.714a.554.554 0 0 1 .787.029c.211.228.2.576-.03.787l-5.472 5.152 8.013 3.936a.551.551 0 0 1 .258.74.559.559 0 0 1-.353.304ZM51.811 365.747a.458.458 0 0 1-.239.004.561.561 0 0 1-.457-.642l2.376-13.521a.563.563 0 0 1 .642-.457c.3.055.512.342.457.642l-2.375 13.521a.554.554 0 0 1-.404.453ZM59.415 364.968a.567.567 0 0 1-.472-.083.553.553 0 0 1-.118-.778l5.628-7.651-7.72-2.845a.555.555 0 0 1-.329-.721.556.556 0 0 1 .722-.328l8.42 3.107a.53.53 0 0 1 .337.359.543.543 0 0 1-.094.49l-6.068 8.248a.626.626 0 0 1-.306.202ZM60.899 314.424l3.835 14.315 6.017-1.388-4.532-16.914-5.32 3.987ZM100.67 423.507l-15.993 4.285a.56.56 0 0 1-.684-.371L73.5 394.47c-.041-.154-.036-.305.05-.433a.525.525 0 0 1 .346-.272l17.546-4.702a.566.566 0 0 1 .692.399l8.941 33.367a.583.583 0 0 1-.404.678Zm-15.777 3.074 14.943-4.004-8.652-32.291-16.44 4.405 10.149 31.89Z"
      fill="#4543B9"
    />
    <path
      d="M88.3 401.966a.566.566 0 0 1-.692-.399l-.708-2.642a.566.566 0 0 1 1.092-.293l.707 2.642a.557.557 0 0 1-.4.692ZM92.398 417.258a.649.649 0 0 1-.285.002c-.303-.069-.477-.382-.408-.685l1.202-4.637a.578.578 0 0 1 .685-.408c.303.068.477.381.409.684l-1.202 4.637a.548.548 0 0 1-.401.407Z"
      fill="#4543B9"
    />
    <path
      d="M450.034 278.992a2397.805 2397.805 0 0 1-10.582-10.575c-.785-.787-2.007.437-1.222 1.224 3.535 3.517 7.07 7.035 10.582 10.575.786.787 2.008-.437 1.222-1.224ZM520.335 259.59c-2.073 2.403-4.145 4.785-6.218 7.188-.742.852.524 2.076 1.244 1.224 2.072-2.404 4.145-4.785 6.218-7.189.742-.83-.524-2.076-1.244-1.223ZM539.187 272.416c-5.149 2.337-10.277 4.697-15.426 7.035-1.026.481-.131 1.967.894 1.508 5.15-2.338 10.277-4.698 15.427-7.036 1.047-.481.152-1.966-.895-1.507ZM527.099 404.914c-14.51 2.032-13.353 23.729 2.684 23.62 18.197-.131 15.251-26.155-2.684-23.62ZM441.241 417.871l2.553 7.626 7.309-5.834-9.862-1.792ZM326.386 336.523l7.527 18.77 12.284-15.383-19.811-3.387ZM628.405 298.548l-16.91 21.304 20.925 18.725 20.728-18.725-24.743-21.304Z"
      fill="#fff"
    />
    <path
      d="M618.303 314.52h-10.801l-1.723 12.717 10.145 3.365 2.379-16.082ZM348.51 306.545c-.48 3.365-.96 6.708-1.44 10.073-.153 1.093 1.506 1.573 1.68.459.48-3.365.96-6.708 1.44-10.073.153-1.092-1.527-1.573-1.68-.459ZM622.317 289.087c-.174-1.092-1.854-.634-1.68.459l.72 4.326c.175 1.093 1.855.634 1.68-.459l-.72-4.326ZM658.144 331.629c-2.64.24-5.28.481-7.898.721-1.113.109-1.113 1.835 0 1.726 2.64-.24 5.28-.48 7.898-.721 1.091-.087 1.113-1.813 0-1.726ZM336.99 321.622c-1.44-.962-2.88-1.923-4.32-2.863-.939-.611-1.811.874-.873 1.508 1.44.961 2.88 1.923 4.32 2.862.938.612 1.811-.895.873-1.507ZM423.699 282.663a147.193 147.193 0 0 0-63.821 36.293c-.807.787.415 2.01 1.222 1.224a145.69 145.69 0 0 1 63.057-35.834c1.069-.306.611-1.967-.458-1.683ZM595.589 290.66c-9.251-7.844-19.986-13.328-31.79-16.169-1.091-.262-1.549 1.421-.458 1.683 11.498 2.775 21.993 8.062 31.004 15.71.851.721 2.095-.503 1.244-1.224Z"
      fill="#fff"
    />
    <path
      d="M559.326 506.167a.887.887 0 0 1-.873-.852l-3.164-111.129c-.022-.481.371-.874.851-.896.458 0 .873.371.895.852l3.163 111.129c.022.481-.37.874-.85.896h-.022ZM419.64 510.34a.875.875 0 0 1-.872-.874v-16.759c0-.48.392-.874.872-.874s.873.394.873.874v16.759a.862.862 0 0 1-.873.874ZM472.573 445.861h-.065l-24.634-2.054a.824.824 0 0 1-.589-.306.871.871 0 0 1-.196-.655l1.549-14.05a.854.854 0 0 1 .851-.765h23.084c.48 0 .873.394.873.874v16.104a.86.86 0 0 1-.283.633 1.036 1.036 0 0 1-.59.219Zm-23.695-3.715 22.823 1.901v-14.29h-21.448l-1.375 12.389ZM534.605 422.547c-8.706 0-13.135-6.184-13.55-11.952-.414-5.659 2.815-12.258 10.866-13.394 5.629-.809 10.539 1.114 13.462 5.222 2.837 3.977 3.295 9.439 1.157 13.634-2.117 4.152-6.328 6.468-11.826 6.512-.022-.022-.066-.022-.109-.022Zm-2.444-23.62c-6.96.983-9.731 6.664-9.382 11.559.371 5.156 4.189 10.335 11.826 10.335h.131c4.822-.044 8.487-2.01 10.298-5.55 1.855-3.605 1.44-8.369-1.025-11.821-1.506-2.119-5.04-5.484-11.848-4.523Z"
      fill="#4543B9"
    />
    <path
      d="M416.019 420.187c-.611-4.37-2.641-10.597-.175-15.492 3.513-6.948 9.491-14.617 11.739-11.952 3.687 4.349 13.855 67.604 11.978 85.543-1.876 17.939-19.266 27.029-19.266 27.029l-.633-12.586s8.444-7.91 8.902-23.314c.459-15.404-8.989-58.755-8.989-58.755l-3.556 9.527ZM566.024 476.91c-1.287 6.161-4.014 11.187-7.113 15.185l-.327-5.2c-.589-8.937-3.229-89.017-2.138-91.683-1.637-8.368 3.971 1.486 6.807 7.845 2.4 5.375 5.281 12.367 4.866 19.249.458 21.217 0 44.596-2.095 54.604Z"
      fill="#4543B9"
    />
    <path
      d="M404.607 449.619a.876.876 0 0 1-.873-.874l-.043-66.053c0-5.003 3.905-9.024 8.88-9.155 21.361-.546 21.448-.437 21.819.022.175.219.24.503.175.765-.109.459-.59.765-1.048.634-.022 0-.043-.022-.065-.022-.589-.044-3.906-.11-20.837.349a7.369 7.369 0 0 0-7.201 7.407l.044 66.053a.844.844 0 0 1-.851.874ZM562.228 413.326a.876.876 0 0 1-.873-.874v-31.376c0-4.086-3.316-7.408-7.397-7.408a.875.875 0 0 1 0-1.748c5.041 0 9.121 4.108 9.121 9.134v31.376c0 .503-.371.896-.851.896ZM408.404 510.34a.876.876 0 0 1-.873-.874v-6.839c0-.481.393-.874.873-.874s.872.393.872.874v6.839a.861.861 0 0 1-.872.874ZM540.3 372.816c-.829-13.132-11.259-11.493-11.259-11.493-2.902-7.844-10.408-8.827-10.408-8.827-.632-9.592-5.411-19.512-26.183-18.442-20.771 1.071-29.783 9.964-29.062 23.839-8.663.109-11.761 5.353-11.717 8.805-1.68-3.845-2.139-8.434-2.182-13.722-.262-23.445 11.04-31.136 53.958-31.551 35.783-.35 39.296 7.342 40.322 24.953.676 12.061.764 20.692-3.469 26.438Z"
      fill="#4543B9"
    />
    <path
      d="M505.476 382.933c-48.678 0-56.642-8.828-56.86-29.935-.109-10.138 1.92-16.802 6.611-21.587 7.288-7.451 21.252-10.598 48.198-10.838v.874-.874c35.915-.328 40.126 7.582 41.195 25.761.742 12.892.829 22.484-4.844 28.514-5.346 5.659-15.601 8.085-34.3 8.085Zm-2.029-60.634c-26.444.24-40.038 3.234-46.976 10.313-4.342 4.436-6.24 10.707-6.131 20.364.218 19.185 5.716 28.209 55.136 28.209 18.176 0 28.06-2.251 33.034-7.538 5.215-5.528 5.106-14.771 4.386-27.226-.938-16.081-3.142-24.45-39.449-24.122Z"
      fill="#4543B9"
    />
    <path
      d="M472.551 369.582a.979.979 0 0 1-.349-.065c-.436-.197-.632-.699-.436-1.136 3.076-7.058 10.779-24.385 10.779-24.385.196-.437.698-.634 1.112-.459.24.088 23.652 9.614 34.823 7.888a.866.866 0 0 1 .982.721c.066.481-.24.918-.72.983-10.582 1.639-30.677-5.986-34.954-7.669-1.527 3.431-7.746 17.436-10.451 23.62a.842.842 0 0 1-.786.502Z"
      fill="#4543B9"
    />
    <path
      d="M462.646 376.334a.866.866 0 0 1-.851-.721c-.284-1.813.131-3.452 1.2-4.741 1.702-2.011 4.931-3.016 9.557-3.016a.875.875 0 0 1 0 1.748c-4.059 0-6.895.831-8.248 2.404-.764.896-1.026 2.01-.807 3.343a.884.884 0 0 1-.72 1.005c-.044-.022-.088-.022-.131-.022ZM499.956 378.956a.875.875 0 0 1 0-1.748c1.615 0 2.946-.437 3.579-1.158.349-.415.458-.918.371-1.529-.24-1.377-3.273-2.142-4.713-2.295a.852.852 0 0 1-.764-.852v-7.953c0-.481.393-.874.873-.874s.872.393.872.874v7.188c1.615.284 5.062 1.18 5.455 3.628.197 1.114-.065 2.141-.742 2.927-1.003 1.158-2.749 1.792-4.931 1.792ZM487.825 359.029c-1.418.153-2.793.393-4.168.808-1.069.328-.349 1.901.72 1.574 1.201-.372 2.466-.547 3.732-.678 1.112-.131.829-1.835-.284-1.704ZM512.873 359.684c-.894-.59-1.876-1.005-2.727-1.66-.873-.678-2.095.568-1.2 1.245.96.743 2.073 1.246 3.076 1.923.938.612 1.768-.918.851-1.508Z"
      fill="#4543B9"
    />
    <path
      d="M519.811 381.84a.87.87 0 0 1-.872-.852c-.066-8.172-.284-15.12-1.179-28.427-.85-12.695-9.12-18.463-25.266-17.633-11.171.568-19.157 3.431-23.739 8.522-3.295 3.671-4.822 8.5-4.517 14.399a.877.877 0 0 1-.829.918c-.523 0-.894-.35-.916-.831-.327-6.358 1.331-11.624 4.953-15.644 4.909-5.441 13.288-8.5 24.939-9.09 17.062-.874 26.183 5.594 27.077 19.25.895 13.307 1.113 20.539 1.179 28.536.043.459-.328.852-.83.852.022 0 0 0 0 0Z"
      fill="#4543B9"
    />
    <path
      d="M422.39 404.958a.712.712 0 0 1-.371-.088c-.437-.196-.611-.721-.415-1.158l.546-1.136c3.338-6.905 13.484-27.881 12.851-47.152-.611-18.18-.436-30.678 6.917-38.413 7.898-8.303 23.302-11.296 61.071-11.886 31.398-.59 49.246 1.639 51.384 54.538.131 3.386.24 6.533.327 9.461.349 11.209.589 19.315 2.575 25.826a.886.886 0 0 1-.567 1.093.883.883 0 0 1-1.091-.568c-2.051-6.73-2.291-14.924-2.641-26.286-.087-2.928-.196-6.074-.327-9.461-2.182-53.795-21.055-53.423-49.616-52.877-37.224.568-52.322 3.431-59.85 11.34-6.895 7.233-7.026 19.425-6.437 37.167.655 19.687-9.644 40.969-13.026 47.961l-.545 1.136a.852.852 0 0 1-.785.503Z"
      fill="#4543B9"
    />
    <path
      d="m451.671 366.72-.873-.022c-.022-2.097.895-4.282 2.575-6.008 1.592-1.639 4.625-3.606 10.015-3.671.436 0 .872.371.872.852a.857.857 0 0 1-.851.874c-4.8.065-7.418 1.748-8.793 3.146-1.331 1.355-2.094 3.147-2.072 4.785l-.873.044ZM539.492 374.149c0 .066 0 .109.022.175a17.48 17.48 0 0 1 1.68-1.42v-.131c-.283-4.633-1.767-8.041-4.385-10.139-2.684-2.163-5.782-2.294-7.157-2.229-3.207-7.669-10.56-8.74-10.866-8.783a.865.865 0 0 0-.982.743.867.867 0 0 0 .742.983c.284.044 7.026 1.049 9.731 8.281a.843.843 0 0 0 .939.546c.043 0 3.643-.524 6.523 1.814 2.204 1.792 3.47 4.763 3.732 8.893 0 .415.021.83.021 1.267ZM486.276 376.509c-1.2 0-2.444-.284-3.579-.852-3.011-1.508-4.756-4.567-4.756-8.412 0-.481.393-.874.873-.874s.872.393.872.874c.022 3.168 1.397 5.659 3.797 6.86 2.051 1.027 4.451.853 6.131-.393 1.92-1.442 2.64-4.064 2.051-7.385a.868.868 0 0 1 .698-1.005.867.867 0 0 1 1.004.699c.96 5.397-1.287 7.997-2.727 9.068-1.244.939-2.793 1.42-4.364 1.42ZM510.844 375.526a6.593 6.593 0 0 1-3.12-.765c-2.815-1.42-4.408-4.567-4.408-8.587 0-.481.393-.874.873-.874s.873.393.873.874c0 3.343 1.265 5.921 3.469 7.036a5.094 5.094 0 0 0 5.302-.394c1.898-1.376 2.706-3.976 2.204-7.123a.883.883 0 0 1 .72-1.005.884.884 0 0 1 1.004.721c.611 3.802-.437 7.014-2.902 8.806-1.222.874-2.597 1.311-4.015 1.311Z"
      fill="#4543B9"
    />
    <path
      d="M492.712 370.456a.838.838 0 0 1-.763-.458c-.24-.416-.066-.94.349-1.18.262-.153 6.546-3.518 12.24-.809.437.197.611.721.415 1.158-.196.437-.72.612-1.157.415-4.909-2.337-10.604.722-10.669.765a.973.973 0 0 1-.415.109ZM507.396 366.502l-.174 1.726c-.24 2.228 3.207 2.578 3.447.371l.175-1.726c.218-2.229-3.229-2.6-3.448-.371ZM484.028 367.048l-.174 1.726c-.24 2.229 3.207 2.578 3.447.371l.175-1.726c.24-2.228-3.208-2.6-3.448-.371ZM391.036 458.84c-1.179 0-2.422-.131-3.753-.437-8.422-1.858-11.259-16.06-14.554-32.491-.916-4.611-1.876-9.374-2.989-14.006a83.625 83.625 0 0 1-.545-2.251c-2.029-8.39-4.037-16.343-6.611-21.282-7.201-13.918-19.965-23.095-24.787-24.1-.72-.153-1.047-.066-1.113-.022-.109.262-.48 1.857 4.408 6.883 2.225 2.272 2.749 5.637 1.374 8.565-1.09 2.338-1.505 4.479-1.221 6.402a.866.866 0 0 1-.72.983.865.865 0 0 1-.982-.721c-.328-2.272.131-4.763 1.374-7.407 1.069-2.272.655-4.872-1.047-6.62-4.015-4.13-5.564-7.014-4.735-8.828.175-.371.655-1.027 1.877-1.07h.087c4.975 0 19.332 10.247 27.034 25.127 2.662 5.113 4.713 13.176 6.764 21.675.174.743.349 1.486.545 2.229 1.113 4.676 2.073 9.461 3.011 14.093 3.033 15.186 5.891 29.52 13.223 31.136 13.898 3.081 19.353-12.869 25.637-31.311 2.487-7.254 5.04-14.77 8.313-21.697.196-.437.72-.611 1.156-.415.437.197.611.721.415 1.158-3.229 6.817-5.782 14.29-8.226 21.501-5.782 16.89-11.237 32.906-23.935 32.906Z"
      fill="#4543B9"
    />
    <path
      d="M328.611 384.288a.868.868 0 0 1-.327-.066c-4.865-2.054-18.306-6.752-22.102-3.955-.721.546-1.026 1.377-.917 2.557a.86.86 0 0 1-.785.939.861.861 0 0 1-.939-.786c-.218-2.251.742-3.496 1.615-4.108 3.36-2.469 11.346-.59 17.193 1.311-1.876-.852-3.862-1.552-5.651-2.185-3.709-1.289-6.415-2.229-6.982-3.977-.153-.459-.218-1.202.393-2.098 3.578-5.265 24.655 2.819 31.027 5.441a.88.88 0 0 1 .48 1.136.886.886 0 0 1-1.135.481c-15.23-6.227-27.143-8.718-28.932-6.052-.24.349-.196.502-.175.568.306.917 3.273 1.966 5.892 2.884 4.08 1.42 9.185 3.212 12.022 6.489a.856.856 0 0 1 .044 1.071.924.924 0 0 1-.721.35Z"
      fill="#4543B9"
    />
    <path
      d="M324.422 386.997c-1.353-.35-2.989-.874-4.735-1.398-6.742-2.098-14.378-4.48-16.8-1.683-1.091 1.246-.982 3.19.327 5.943 0 .022.022.022.022.044.502-.022 1.003 0 1.483.044a.864.864 0 0 0 .044-.831c-.96-1.988-1.156-3.408-.589-4.064 1.68-1.944 9.666.568 14.968 2.207 1.767.546 3.425 1.071 4.822 1.442.458.109.938-.153 1.047-.612.153-.502-.109-.983-.589-1.092Z"
      fill="#4543B9"
    />
    <path
      d="M385.494 510.34c-2.968 0-6.088-.306-9.383-.961-32.554-6.533-38.379-51.763-41.172-73.482a337.495 337.495 0 0 0-.72-5.375 136.669 136.669 0 0 0-1.397-7.757 24.106 24.106 0 0 0-.392-1.791c-2.357-10.401-5.433-17.327-8.466-19.054-3.447-1.966-7.789-3.102-11.63-4.107-5.367-1.399-9.6-2.513-10.473-5.419-.262-.874-.523-2.141.306-3.037 1.571-1.683 5.651-.852 18.371 2.01.459.109.764.568.655 1.027-.109.459-.567.765-1.026.655-8.814-1.988-15.753-3.561-16.713-2.534-.153.175-.131.655.087 1.354.589 1.967 4.801 3.081 9.252 4.239 3.927 1.027 8.4 2.185 12.044 4.283 4.734 2.687 7.855 13.787 9.295 20.146.152.59.305 1.245.414 1.879a118.655 118.655 0 0 1 1.418 7.844c.219 1.529.459 3.343.72 5.419 2.75 21.325 8.466 65.725 39.798 72.017 34.06 6.861 50.184-26.679 50.337-27.028.196-.437.72-.612 1.156-.415.437.196.611.721.415 1.158-.175.306-13.942 28.929-42.896 28.929Z"
      fill="#4543B9"
    />
    <path
      d="M333.259 420.711c.174.612.283 1.224.414 1.836 6.895 1.267 14.052 1.071 20.925-.743 6.153-1.639 12.458-5.047 16.037-10.117-.197-.764-.371-1.507-.546-2.25-3.709 6.642-12.546 10.313-20.03 11.58a45.186 45.186 0 0 1-16.8-.306ZM397.909 508.461a.865.865 0 0 1-.83-.612c-1.658-5.419-7.374-10.881-14.88-14.202-9.448-4.195-19.943-4.239-28.758-.131a.877.877 0 0 1-1.156-.415.88.88 0 0 1 .415-1.159c9.273-4.326 20.269-4.304 30.197.11 7.964 3.539 14.03 9.395 15.841 15.295a.884.884 0 0 1-.568 1.092c-.087 0-.174.022-.261.022ZM586.84 447.893c-13.135 0-19.637-20.146-24.503-35.2l-.327-1.006c-2.379-7.429-4.43-13.852-6.611-16.715a.893.893 0 0 1 .152-1.223.89.89 0 0 1 1.222.153c2.357 3.102 4.473 9.635 6.895 17.217l.327 1.005c6.371 19.753 13.179 37.189 26.314 33.496 14.051-3.954 17.346-27.771 20.27-48.791l.065-.502c.131-.787.219-1.53.328-2.294 1.2-8.675 2.465-16.89 4.603-22.244 6.241-15.644 19.659-27.29 24.547-27.662 1.244-.087 1.745.503 1.964.874 1.025 1.726-.262 4.785-3.928 9.33-1.484 1.836-1.636 4.436-.393 6.49 1.55 2.556 2.27 5.025 2.139 7.341a.879.879 0 0 1-.917.831.877.877 0 0 1-.829-.918c.087-1.967-.545-4.108-1.898-6.359-1.615-2.665-1.397-6.074.524-8.477 4.451-5.55 3.927-7.123 3.774-7.364-.043-.022-.371-.087-1.091.131-4.713 1.486-16.517 11.865-22.321 26.417-2.072 5.178-3.316 13.285-4.516 21.828-.109.765-.218 1.508-.327 2.294l-.066.481c-2.989 21.544-6.371 45.972-21.513 50.211a11.327 11.327 0 0 1-3.884.656Z"
      fill="#4543B9"
    />
    <path
      d="M650.53 365.125a.944.944 0 0 1-.655-.284.887.887 0 0 1-.065-1.071c2.509-3.539 7.396-5.812 11.324-7.625 2.531-1.158 5.389-2.491 5.585-3.431.022-.065.044-.218-.218-.546-2.051-2.469-13.659 1.18-28.212 8.849a.877.877 0 0 1-1.178-.371.88.88 0 0 1 .371-1.18c6.087-3.212 26.27-13.329 30.35-8.434.676.808.698 1.551.589 2.032-.393 1.77-2.989 2.971-6.546 4.632-1.723.809-3.643 1.683-5.433 2.731 5.63-2.469 13.419-5.134 16.998-2.993.916.546 2.007 1.66 2.007 3.933a.876.876 0 0 1-.873.874.876.876 0 0 1-.873-.874c0-1.18-.371-1.988-1.156-2.447-4.037-2.404-16.975 3.583-21.601 6.096a.7.7 0 0 1-.414.109Z"
      fill="#4543B9"
    />
    <path
      d="M655.351 369.08a.867.867 0 0 1-.807-.568c-.174-.459.066-.94.524-1.115 1.309-.48 2.902-1.158 4.582-1.857 6.502-2.753 13.877-5.877 16.56-3.321 1.201 1.136 1.288 3.081.24 5.943a.874.874 0 0 1-1.112.525c-.458-.153-.677-.656-.524-1.115.764-2.075.829-3.496.196-4.108-1.854-1.769-9.578 1.508-14.684 3.671-1.702.721-3.316 1.399-4.647 1.901-.131.022-.24.044-.328.044Z"
      fill="#4543B9"
    />
    <path
      d="M595.24 498.17c-24.503 0-36.503-17.021-36.634-17.218-.262-.393-.175-.94.218-1.202s.938-.175 1.2.219c.153.218 15.295 21.609 46.431 15.295 32.576-6.599 37.965-53.97 40.845-79.425.175-1.53.328-2.972.502-4.348.153-1.355.24-2.71.327-4.064.088-1.311.175-2.6.306-3.868.044-.612.131-1.267.24-1.923.807-6.445 2.836-17.785 7.288-20.932 3.425-2.425 7.745-4.02 11.564-5.441 4.32-1.595 8.4-3.102 8.793-5.112.131-.721.131-1.202-.044-1.355-1.047-.94-7.811 1.311-16.386 4.151a.854.854 0 0 1-1.091-.546.855.855 0 0 1 .545-1.092c12.372-4.086 16.365-5.332 18.088-3.802.917.808.786 2.097.611 2.993-.567 2.972-4.691 4.501-9.905 6.402-3.71 1.377-7.921 2.928-11.172 5.222-2.858 2.011-5.258 9.221-6.567 19.753a19.153 19.153 0 0 0-.219 1.835c-.152 1.267-.218 2.513-.305 3.802-.087 1.377-.175 2.753-.349 4.152-.153 1.376-.327 2.818-.502 4.326-2.946 25.892-8.422 74.071-42.22 80.91a52.812 52.812 0 0 1-11.564 1.268Z"
      fill="#4543B9"
    />
    <path
      d="M649.548 401.833a18.521 18.521 0 0 0-.219 1.879c-6.742 1.945-13.876 2.447-20.88 1.311-6.284-1.027-12.874-3.802-16.932-8.499.131-.787.218-1.53.327-2.295 4.342 6.249 13.485 9.046 21.056 9.571 5.651.371 11.28-.328 16.648-1.967ZM585.247 497.361c-.109 0-.196-.022-.306-.043a.877.877 0 0 1-.523-1.115c6.764-18.485 33.928-25.826 48.242-21.566a.865.865 0 0 1-.48 1.661c-13.703-4.064-39.689 2.906-46.126 20.517a.877.877 0 0 1-.807.546ZM500.327 246.676c-3.971-3.867-12.589-7.604-31.114-3.43a.818.818 0 0 0-.611.546.818.818 0 0 0 .11.808l3.709 5.092a.838.838 0 0 0 .938.327c7.877-2.36 14.597-2.075 18.939.787 2.662 1.748 4.276 4.457 4.669 7.844 1.004 8.565-15.273 10.881-15.426 10.903a.876.876 0 0 0-.742.896l.72 14.508a.884.884 0 0 0 .873.831h3.164c.24 0 .458-.088.633-.262.152-.175.261-.394.24-.634l-.328-10.379c3.273-.721 16.146-4.108 18.066-13.94 1.048-5.506-.305-10.444-3.84-13.897ZM486.167 288.825h-4.342a.866.866 0 0 0-.851.677l-1.222 5.463c-.044.24 0 .48.131.677a.898.898 0 0 0 .589.372l5.542.852h.131a.806.806 0 0 0 .567-.219.884.884 0 0 0 .306-.655v-6.315c.022-.459-.371-.852-.851-.852Z"
      fill="#4543B9"
    />
    <path
      d="M700.066 246.328h-43.332a1.278 1.278 0 0 1-1.282-1.281c0-.697.557-1.282 1.282-1.282h43.332c.697 0 1.282.557 1.282 1.282 0 .696-.557 1.281-1.282 1.281ZM711.52 265.863h-54.786a1.278 1.278 0 0 1-1.282-1.282c0-.696.557-1.282 1.282-1.282h54.786a1.28 1.28 0 0 1 1.282 1.282c0 .725-.558 1.282-1.282 1.282ZM634.83 285.788h-60.805c-.669 0-1.226-.53-1.282-1.199l-4.459-84.659a1.197 1.197 0 0 1 .363-.947 1.31 1.31 0 0 1 .919-.391h65.236c.697 0 1.282.558 1.282 1.282v84.66c.028.669-.557 1.254-1.254 1.254Zm-59.579-2.564h58.325V201.1h-62.644l4.319 82.124Z"
      fill="#7977E7"
    />
    <path
      d="M626.33 240.225h-48.265a1.278 1.278 0 0 1-1.282-1.281c0-.725.558-1.282 1.282-1.282h48.265c.697 0 1.282.557 1.282 1.282 0 .724-.585 1.281-1.282 1.281Z"
      fill="#7977E7"
    />
    <path
      d="M603.564 264.804a1.286 1.286 0 0 1-1.282-1.226l-1.365-48.711a1.273 1.273 0 0 1 1.226-1.31c.696 0 1.282.529 1.309 1.226l1.366 48.711a1.273 1.273 0 0 1-1.226 1.31h-.028ZM598.743 264.832c-.279 0-.558-.084-.808-.279-7.524-6.075-11.286-16.274-10.339-27.978.836-10.255 5.155-19.563 10.506-22.6a1.246 1.246 0 0 1 1.728.474 1.244 1.244 0 0 1-.474 1.727c-4.598 2.62-8.472 11.287-9.224 20.594-.892 10.868 2.536 20.259 9.391 25.777.557.446.641 1.254.195 1.783a1.214 1.214 0 0 1-.975.502ZM608.329 264.358c-.251 0-.502-.083-.697-.223a1.263 1.263 0 0 1-.362-1.755c11.871-17.724 8.36-38.624-2.229-46.287a1.283 1.283 0 0 1-.279-1.784 1.284 1.284 0 0 1 1.784-.278c11.509 8.332 15.521 30.82 2.842 49.742-.251.39-.641.585-1.059.585Z"
      fill="#7977E7"
    />
    <path
      d="M602.198 264.832c-14.017 0-25.415-11.621-25.415-25.888 0-14.017 11.398-25.415 25.415-25.415s25.414 11.398 25.414 25.415c0 17.166-8.555 25.888-25.414 25.888Zm0-48.767c-12.596 0-22.851 10.255-22.851 22.851 0 12.651 10.478 23.324 22.851 23.324 15.382 0 22.851-7.635 22.851-23.324 0-12.568-10.255-22.851-22.851-22.851Z"
      fill="#7977E7"
    />
    <path
      d="m236.666 228.331 1.098 40.821h.001c.83.599 1.698 1.181 2.561 1.679 2.103 1.214 4.38 2.034 6.647 1.373 1.408-.398 2.691-1.319 3.96-2.78 1.267-1.458 2.544-3.481 3.937-6.127 2.784-5.291 6.088-13.183 10.74-24.296l.005-.013c2.983-7.114 6.696-15.969 11.135-26.218a1.164 1.164 0 0 0-.609-1.556 1.165 1.165 0 0 0-1.554.616c-4.062 9.336-7.492 17.52-10.324 24.277l-.83 1.98v.001c-4.433 10.603-7.655 18.295-10.331 23.499-1.337 2.602-2.526 4.558-3.647 5.936-1.124 1.381-2.143 2.138-3.13 2.419-1.634.458-3.646-.205-7.256-2.784-3.188-2.277-5.531-3.954-7.099-5.656-1.54-1.67-2.31-3.343-2.347-5.622-.038-2.321.683-5.297 2.223-9.562 1.537-4.256 3.869-9.738 7.008-17.057l.001-.001 2.548-5.987h.001c3.644-8.553 6.456-15.152 8.905-19.75 1.224-2.298 2.344-4.073 3.417-5.332 1.076-1.264 2.065-1.964 3.017-2.198l.003-.001c1.394-.352 3.124.248 5.523 2.22 2.284 1.872 3.898 3.203 4.84 4.784.916 1.538 1.219 3.361.699 6.274-.523 2.931-1.871 6.907-4.2 12.701-2.327 5.786-5.618 13.349-10.012 23.447-.27.591-.017 1.29.607 1.555.618.28 1.289-.037 1.549-.6l.005-.01.004-.009c4.49-10.322 7.863-18.075 10.24-24.019 2.375-5.937 3.772-10.107 4.29-13.252.523-3.168.163-5.347-1.019-7.257-1.146-1.851-3.044-3.408-5.45-5.382l-.06-.048v-.001c-2.811-2.303-5.253-3.269-7.585-2.686-1.387.341-2.654 1.212-3.886 2.562-1.231 1.349-2.45 3.202-3.738 5.556-2.575 4.705-5.479 11.512-9.324 20.526v.001l-2.563 5.977Z"
      fill="#7977E7"
      stroke="#7977E7"
    />
    <path
      d="M573.033 125.403c16.685 17.784 37.161 2.161 22.017-19.466L623.089 76.8l-10.114-9.37-7.368 7.368 6.057 5.385-3.684 4.357-4.588-2.25-4.056 4.73 3.419 3.773-10.433 10.787c-15.233-17.181-37.905 3.967-19.289 23.823Zm17.465-17.748c11.265 15.445-1.612 26.658-13.781 13.78-14.187-15.037 2.498-29.207 13.781-13.78ZM638.782 105.246c2.586.248 5.172.496 7.776.762 1.293.124 1.293-1.895 0-2.019-2.586-.248-5.172-.496-7.776-.762-1.311-.142-1.293 1.895 0 2.019ZM544.764 94.902c3.755.283 7.51.602 11.265.886 1.311.106 1.293-1.931 0-2.02-3.755-.283-7.51-.602-11.265-.885-1.293-.124-1.276 1.913 0 2.019ZM566.302 42.083c2.268 7.44 4.535 14.879 6.784 22.3.39 1.24 2.338.744 1.966-.513-2.267-7.44-4.534-14.879-6.784-22.3-.389-1.24-2.355-.727-1.966.513Z"
      fill="#4543B9"
    />
    <path
      d="m131.402 97.757 29.791 51.601s82.519-53.979 83.395-52.46c.876 1.517-21.917-48.418-21.917-48.418l-91.269 49.277Z"
      fill="#7977E7"
    />
    <path
      d="M164.741 170.961a1.134 1.134 0 0 1-.505.159 1.255 1.255 0 0 1-1.221-.622l-35.293-61.129a1.258 1.258 0 0 1-.122-.958c.084-.314.3-.571.624-.758l91.269-49.278a1.27 1.27 0 0 1 1.012-.086c.328.109.6.35.748.663 0 0 5.527 12.101 11.015 24.192 2.756 6.038 5.502 12.116 7.55 16.64 1.753 3.897 2.717 6.027 3.164 7.262.068.06.097.11.14.184l-.05.029c.427 1.313.164 1.432-.423 1.737-.224.13-.505.159-.766.111-4.397 1.577-44.585 27.002-71.227 44.175l-5.277 16.946c-.148.317-.364.575-.638.733ZM130.543 109.2l33.224 57.546 4.553-14.637c.099-.289.265-.518.525-.701 40.754-26.283 64.77-41.11 71.533-44.219-2.577-5.843-15.165-33.506-20.872-46.034L130.543 109.2Z"
      fill="#4543B9"
    />
    <path
      d="m214.16 94.528-52.795 30.481a1.277 1.277 0 0 1-1.727-.463 1.277 1.277 0 0 1 .463-1.727l52.794-30.48a1.275 1.275 0 0 1 1.727.462 1.275 1.275 0 0 1-.462 1.727Z"
      fill="#4543B9"
    />
  </svg>
)

export default CoachingWays3
